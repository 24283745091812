<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Desertification</h1>
      <p>
        Our land is located in Andalusia in the Valle de los Pedroches. This area is
        part of the age old Spanish agroforestry system called <em>Dehesa</em>. The Dehesa
        is a sort of oak savanna with different types of oak trees dominating the landscape
        while grasses and other plant species fill the spaces between them.
      </p>
      <p>
        Unfortunately the Dehesa is dying due to many different factors that include
        over- and undergrazing. As a result the soil is now compacted, does not hold water
        well due to very little organic matter, the diversity of plant species is declining
        and given that it does not rain for 6 months during the hot part of the year it is
        a challenging situation.
      </p>
      <p>
        Our low temperatures during winter are around -10° Celsius and in summer we can have an ambient
        temperature of 45° Celsius in the shade. During autumn, winter and spring it does rain and we
        do get about 500 mm to 600 mm of rain per year. Older people report that in the past
        there used to be thunderstorms in summer but for a few decades that hasn't been the case
        anymore.
      </p>
      <p>
        The Spanish government has published information about the current state of
        the desertification threat in Spain. Please see their page about
        <a href="https://www.miteco.gob.es/es/biodiversidad/temas/desertificacion-restauracion/lucha-contra-la-desertificacion/lch_espana.aspx"
          target="_blank">La desertificación en España</a> (Spanish)
      </p>

      <p>
        In October 2017, with the animals of the previous owner still present, the land looked like this:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-10-01_18-33-44.jpg">
        <img src="../../assets/images/2017-10-01_18-33-44.jpg">
      </picture>

      <p>
        After some rain in November 2017 a bit of short grass emerged and was immediately consumed:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-11-16_13-03-53.jpg">
        <img src="../../assets/images/2017-11-16_13-03-53.jpg">
      </picture>

      <p>
        With the help of a lot of animals managed in a different way we achieved to get onto the path of restoration as
        this image from April 2022 shows:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-04-19_12-21-42.jpg">
        <img src="../../assets/images/2022-04-19_12-21-42.jpg">
      </picture>

      <p>
        Unlike before we are now (June 2022) able to keep the soil covered with biomass to keep the soil alive instead
        of letting the sun kill it all every year:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-02_14-05-29.jpg">
        <img src="../../assets/images/2022-06-02_14-05-29.jpg">
      </picture>

      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "Desertification",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
